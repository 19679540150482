<template>
  <div class="font-source">
    <section class="flex justify-between items-center">
      <h4 class="font-semibold text-ansBlack text-lg lg:text-xl">
        Institutions
      </h4>

      <button
        @click="showCreate = true"
        class="bg-ansGreen text-white px-4 py-3 rounded flex gap-3 items-center w-1/2 lg:w-auto font-semibold text-sm lg:text-base"
      >
        <i class="fas fa-plus mr-1"></i>
        <span>Create new Institution</span>
      </button>
    </section>

    <section class="my-11">
      <div class="table-box">
        <h4 class="px-2 mb-5">List of Institutions</h4>
        <api-table
          apiUrl="/institutions"
          :fields="fields"
          :show-page="true"
          :show-search="true"
        >
          <template slot="institutionSignature" slot-scope="props">
            <img
              v-if="props.rowData.institutionSignature"
              class="max-h-7"
              :src="props.rowData.institutionSignature"
            />
          </template>

          <template slot="actions" slot-scope="props">
            <div class="flex gap-3">
              <button
                class="px-2.5 py-1.5 text-white bg-ansGreen rounded text-xs"
                @click="editData(props.rowData)"
              >
                Edit
              </button>

              <button
                class="px-2.5 py-1.5 text-white bg-red-500 rounded text-xs"
                @click="deleteRow(props.rowData)"
                :disabled="props.rowData.processing"
              >
                Delete
              </button>
            </div>
          </template>
        </api-table>
      </div>
    </section>

    <Modal v-if="showCreate" v-model="showCreate" size="md">
      <CreateInstitution @done="createDone" />
    </Modal>

    <Modal v-if="showView" v-model="showView" size="md">
      <EditInstitution @done="createDone" :rowData="rowData" />
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/app/Modal.vue";
import CreateInstitution from "@/components/admin/CreateInstitution.vue";
import EditInstitution from "@/components/admin/EditInstitution.vue";
export default {
  name: "AdminReceipts",

  components: {
    Modal,
    CreateInstitution,
    EditInstitution,
  },

  data() {
    let sv = "hidden lg:table-cell";
    let tc = "bg-gray-100 py-3.5 text-cadet font-semibold text-sm";
    let dc = "py-2 text-xs text-romanSilver";
    return {
      showCreate: false,
      showView: false,
      processing: false,
      rowData: null,
      fields: [
        {
          name: "id",
          title: "S/N",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "institutionName",
          title: "Institution Name",
          titleClass: `${tc} text-left`,
          dataClass: `${dc} uppercase`,
        },
        {
          name: "institutionCode",
          title: "Institution Code",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} uppercase ${sv}`,
        },
        {
          name: "institutionSigneeName",
          title: "Institution Signatory",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv}`,
        },
        {
          name: "institutionSignature",
          title: "Institution Signature",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv}`,
        },
        {
          name: "actions",
          title: "Actions",
          titleClass: `${tc} text-right lg:text-left pr-4`,
          dataClass: `${dc} flex justify-end lg:justify-start`,
        },
      ],
    };
  },

  methods: {
    createDone() {
      this.$events.fire("table-refresh");
      this.showCreate = false;
      this.showView = false;
    },

    editData(data) {
      this.rowData = data;
      this.showView = true;
    },

    async deleteRow(da) {
      try {
        const rep = await this.$swal({
          icon: "question",
          text: "Are you sure you want to delete this institution?",
          showDenyButton: true,
          confirmButtonText: "Yes",
          denyButtonText: "No",
        });

        if (!rep.isConfirmed) {
          return;
        }

        const res = await this.$http.delete("/institutions/" + da.id);

        if (!res) {
          return;
        }

        this.$events.fire("table-refresh");

        this.$swal({
          icon: "success",
          text: "Deleted Successfully",
        });
      } catch (error) {
        console.log({ error });
      }
    },
  },
};
</script>

<style scoped>
.table-box {
  @apply rounded-md py-4 px-2 w-full mt-5 lg:mt-0;
  border: 0.5px solid #d9d9d9;
  box-shadow: 0px 4px 11px rgba(200, 212, 219, 0.2);
}
.table-box h4 {
  @apply text-romanSilver font-bold;
}
</style>
