<template>
  <transition name="slidex" appear="">
    <nav
      class="w-56 lg:w-60 bg-britGray absolute lg:static left-0 bottom-0 top-px font-source"
    >
      <ul class="mt-18">
        <li class="truncate pr-2 mb-2">
          <span class="text-xs uppercase bg-ansGreen text-white px-2 py-1">
            {{ $store.getters["general/userType"] }}
          </span>
          <span class="text-sm font-bold pl-1">
            {{ $store.getters["general/userNames"] }}
          </span>
        </li>

        <hr />
        <li>
          <router-link
            :to="{ name: 'Admin Dashboard' }"
            active-class="nav-active"
            @click.native="closeNav"
          >
            <img
              src="@/assets/images/icons/dashboard.svg"
              alt="icons"
              class="h-6 w-6"
            />
            <span>Dashboard</span>
          </router-link>
        </li>

        <li>
          <router-link
            :to="{ name: 'Admin Invoices' }"
            active-class="nav-active"
            @click.native="closeNav"
          >
            <i class="fas fa-file-invoice text-xl mr-1"></i>
            <span>Invoices</span>
          </router-link>
        </li>

        <li>
          <router-link
            :to="{ name: 'Admin Transactions' }"
            active-class="nav-active"
            @click.native="closeNav"
          >
            <i class="fas fa-list text-xl mr-1"></i>
            <span>Transactions</span>
          </router-link>
        </li>

        <li>
          <router-link
            :to="{ name: 'Admin Receipts' }"
            active-class="nav-active"
            @click.native="closeNav"
          >
            <i class="fa fa-receipt text-xl mr-1"></i>
            <span>Receipts</span>
          </router-link>
        </li>

        <menu-group label="Revenue Settings" icon="fas fa-layer-group">
          <li>
            <router-link
              :to="{ name: 'Admin Revenue Head' }"
              active-class="nav-active"
              @click.native="closeNav"
            >
              <i class="fa fa-list text-lg mr-1"></i>
              <span>Revenue Heads</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'Admin Revenue SubHead' }"
              active-class="nav-active"
              @click.native="closeNav"
              class="truncate"
            >
              <i class="fa fa-list text-lg mr-1"></i>
              <span>Revenue SubHeads</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'Admin Revenues' }"
              active-class="nav-active"
              @click.native="closeNav"
            >
              <img
                src="@/assets/images/icons/revenues.svg"
                alt="icons"
                class="h-6 w-6"
              />
              <span>Revenues</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'Admin Institutions' }"
              active-class="nav-active"
              @click.native="closeNav"
            >
              <i class="fas fa-hotel text-xl mr-1"></i>
              <span>Institutions</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'Admin Subscriptions' }"
              active-class="nav-active"
              @click.native="closeNav"
            >
              <i class="fa fa-briefcase text-xl mr-1"></i>
              <span>Subscriptions</span>
            </router-link>
          </li>
        </menu-group>

        <menu-group label="User Settings" icon="fas fa-users-cog">
          <li>
            <router-link
              :to="{ name: 'Admin TaxPayers' }"
              active-class="nav-active"
              @click.native="closeNav"
            >
              <i class="fa fa-users text-xl mr-1"></i>
              <span>Tax Payers</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'Admin Agents' }"
              active-class="nav-active"
              @click.native="closeNav"
            >
              <i class="fas fa-user-shield text-xl mr-1"></i>
              <span>Agents</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'Admin Admins' }"
              active-class="nav-active"
              @click.native="closeNav"
            >
              <i class="fas fa-user-cog text-xl mr-1"></i>
              <span>Admins</span>
            </router-link>
          </li>
        </menu-group>

        <menu-group label="System Settings" icon="fas fa-cog">
          <li>
            <router-link
              :to="{ name: 'Admin Roles' }"
              active-class="nav-active"
              @click.native="closeNav"
            >
              <i class="fa fa-users text-xl mr-1"></i>
              <span>Roles</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'Admin Permissions' }"
              active-class="nav-active"
              @click.native="closeNav"
            >
              <i class="fas fa-user-shield text-xl mr-1"></i>
              <span>Permissions</span>
            </router-link>
          </li>
          <!-- <li>
            <router-link
              :to="{ name: 'Admin Admins' }"
              active-class="nav-active"
              @click.native="closeNav"
            >
              <i class="fas fa-user-cog text-xl mr-1"></i>
              <span>Admins</span>
            </router-link>
          </li> -->
        </menu-group>
      </ul>
    </nav>
  </transition>
</template>

<script>
import MenuGroup from "@/components/app/MenuGroup.vue";
export default {
  name: "SideNav",

  components: {
    MenuGroup,
  },

  methods: {
    closeNav() {
      this.$store.commit("general/toggleNavBar", false);
    },
  },
};
</script>

<style scoped>
nav {
  box-shadow: 0px 0px 4px 0px #a6a5a5;
  z-index: 12;
  @apply pb-10;
}
a {
  @apply hover:bg-ansGreen hover:text-white flex items-center gap-3 px-6 py-3 text-ansBlack mt-1;
}
.nav-active {
  @apply bg-ansGreen text-white;
}
.nav-active > img,
a:hover > img {
  filter: invert(100%) sepia(46%) saturate(147%) hue-rotate(136deg)
    brightness(114%) contrast(100%) !important;
}

/* ****************** Slide transistion */
.slidex-leave-active,
.slidex-enter-active {
  transition: 0.5s;
}
.slidex-enter {
  transform: translate(-100%, 0);
}
.slidex-leave-to {
  transform: translate(-100%, 0);
}
</style>
