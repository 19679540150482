var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"font-source"},[_vm._m(0),_c('section',{staticClass:"my-11"},[_c('div',{staticClass:"table-box"},[_c('h4',{staticClass:"px-2 mb-5"},[_vm._v("List of taxpayers")]),_c('api-table',{attrs:{"apiUrl":"/taxpayer","fields":_vm.fields,"show-page":true,"show-search":true,"detail-row-component":"agent-drop-down","bus":_vm.bus},scopedSlots:_vm._u([{key:"accountName",fn:function(props){return [_c('div',{staticClass:"cursor-pointer"},[(props.rowData.accountName)?_c('span',[_vm._v(_vm._s(props.rowData.accountName))]):(props.rowData.businessName)?_c('span',[_vm._v(_vm._s(props.rowData.businessName))]):_c('span',[_vm._v(_vm._s(props.rowData.firstName)+" "+_vm._s(props.rowData.lastName))])])]}},{key:"status",fn:function(props){return [_c('div',{staticClass:"cursor-pointer"},[(props.rowData.isOnboarded)?_c('span',[_vm._v("Onboarded")]):_c('span',[_vm._v("Not Onboarded")])])]}},{key:"actions",fn:function(props){return [_c('div',{staticClass:"flex gap-3"},[_c('router-link',{staticClass:"px-3 py-1.5 text-white bg-blue-500 rounded",attrs:{"to":{
                name: 'Admin TaxPayer',
                params: {
                  id: props.rowData.userId,
                },
              }}},[_vm._v(" View ")])],1)]}}])})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"block items-center"},[_c('h4',{staticClass:"font-semibold text-ansBlack text-lg lg:text-xl"},[_vm._v("Tax payers")])])
}]

export { render, staticRenderFns }