import { render, staticRenderFns } from "./CreatePermission.vue?vue&type=template&id=cff12f56&scoped=true&"
import script from "./CreatePermission.vue?vue&type=script&lang=js&"
export * from "./CreatePermission.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cff12f56",
  null
  
)

export default component.exports