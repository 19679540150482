<template>
  <div class="font-source">
    <h4 class="px-5 py-2.5 bg-ansLemon text-ansGreen text-center">
      Create an Agent
    </h4>

    <div class="px-4 lg:px-20 mt-2.5">
      <label for="agentName">
        Agent name
        <span class="text-red-400">*</span>
      </label>
      <input
        type="text"
        class="input"
        id="agentName"
        placeholder="Enter agent name"
        v-model="form.name"
      />
    </div>

    <div class="px-4 lg:px-20 mt-2.5">
      <label for="email">
        Agent Email
        <span class="text-red-400">*</span>
      </label>
      <input
        type="email"
        class="input"
        id="email"
        placeholder="Enter agent email"
        v-model="form.email"
      />
    </div>

    <div class="px-4 lg:px-20 mt-4">
      <label for="address">
        Address
        <span class="text-red-400">*</span>
      </label>
      <input
        type="text"
        class="input"
        id="address"
        placeholder="Enter your business address"
        v-model="form.businessAddress"
      />
    </div>

    <div class="px-4 lg:px-20 mt-4">
      <label for="phone">
        Phone number
        <span class="text-red-400">*</span>
      </label>
      <input
        type="number"
        class="input"
        id="phone"
        pattern="/\d*$/"
        onKeyPress="if(this.value.length==11) return false;"
        placeholder="Enter agent phone number"
        v-model="form.phone"
      />
    </div>

    <div class="px-4 lg:px-20 mt-2.5">
      <label for="headId">
        Revenue Heads
        <span class="text-red-400">*</span>
      </label>
      <v-select
        id="headId"
        label="headName"
        :reduce="(b) => b._id"
        :options="revenueHeads"
        v-model="form.revenues"
        multiple
        placeholder="-- select revenue heads --"
      >
      </v-select>
    </div>

    <div class="px-4 lg:px-20 mt-4">
      <label for="agentType" class="block">
        Agent Type
        <span class="text-red-400">*</span>
      </label>

      <label for="agentInst">
        <input
          type="radio"
          class="input"
          v-model="form.agentType"
          value="institution"
          id="agentInst"
        />
        Institution
      </label>

      <label for="agentIndiv" class="ml-3">
        <input
          type="radio"
          class="input"
          v-model="form.agentType"
          value="individual"
          id="agentIndiv"
        />
        Individual
      </label>
    </div>

    <div v-if="form.agentType === 'institution'" class="px-4 lg:px-20 mt-4">
      <label for="institutionCode">
        Agent Institution
        <span class="text-red-400">*</span>
      </label>
      <v-select
        id="institutionCode"
        label="institutionName"
        :reduce="(b) => b.institutionCode"
        :options="institutions"
        v-model="form.institutionCode"
        :filterable="false"
        @search="getInstitutions"
        placeholder="-- select an institution--"
      >
        <template v-slot:no-options="{ search, searching }">
          <template v-if="searching">
            No results found for <em>{{ search }}</em
            >.
          </template>
          <span v-else clas="text-gray-400 lowercase text-xs">
            Start typing to search for an institution
          </span>
        </template>
      </v-select>
    </div>

    <div class="flex justify-center px-4 lg:px-20 mt-8 pb-3">
      <button
        :disabled="processing || !createReady"
        @click="addAgent"
        class="bg-ansGreen text-white w-full text-center py-4 rounded font-semibold text-xl flex gap-2 items-center justify-center"
      >
        <span>Add Agent</span>
        <spinner v-if="processing" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateAgent",

  data() {
    return {
      processing: false,
      form: {
        name: null,
        institutionCode: null,
        phone: null,
        email: null,
        businessAddress: null,
        resetUrl: `${window.location.origin}/reset-password`,
        agentType: "institution",
        revenues: [],
      },
      institutions: [],
      revenueHeads: [],
    };
  },

  computed: {
    createReady() {
      return (
        !!this.form.phone &&
        this.form.email &&
        this.form.name &&
        this.form.agentType &&
        this.form.businessAddress &&
        this.form.revenues
      );
    },
  },

  async created() {
    await this.getRevenues();
  },

  methods: {
    async addAgent() {
      try {
        if (!this.createReady) {
          this.$swal({
            icon: "error",
            text: "Fill all required fields",
          });
          return;
        }
        this.processing = true;

        const res = await this.$http.post("/auth/create-agent", this.form);

        this.processing = false;
        if (!res) {
          return;
        }

        const { data } = res;

        this.$swal({
          icon: "success",
          text: data.message,
        });
        // console.log(data.newUser);
        this.$emit("add-agent", data.newUser);
      } catch (error) {
        this.processing = false;
        console.log({ error });
      }
    },

    async getRevenues() {
      try {
        const res = await this.$http.get("/revenue-head/head?limit=100");

        if (!res) {
          this.revenueHeads = [];
          return;
        }

        const { data } = res;
        this.revenueHeads = data.data.data;
      } catch (err) {
        console.log(err);
      }
    },

    async getInstitutions(search, loading) {
      if (search.length < 2) {
        return;
      }

      try {
        loading(true);
        const res = await this.$http.get("/institutions?search=" + search);

        if (!res) {
          this.institutions = [];
          return;
        }

        const { data } = res;
        this.institutions = data.data.data;
        loading(false);
      } catch (err) {
        loading(false);
        console.log(err);
      }
    },
  },
};
</script>

<style scoped></style>
