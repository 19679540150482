<template>
  <div class="font-source">
    <div v-if="getting" class="flex justify-center items-center h-[50vh]">
      <spinner class="w-20 h-20" color="ansGreen" />
    </div>

    <div
      v-else-if="!getting && !agent"
      class="flex flex-col gap-4 justify-center items-center h-[50vh]"
    >
      <img
        class="mx-auto"
        src="@/assets/images/notfound.svg"
        alt="loading icon"
        width="168"
        height="170"
      />
      <p class="text-center fw-bold">Admin Not Found!</p>
    </div>

    <div v-else>
      <section class="flex gap-4 items-center">
        <button @click="$router.go(-1)" class="p-4">
          <img src="@/assets/images/icons/back-arrow.svg" alt="icons" />
        </button>

        <h4 class="font-semibold text-ansBlack text-lg lg:text-xl">
          {{ agent.companyName }}
        </h4>
      </section>

      <section class="mb-11 mt-4">
        <div class="table-box">
          <h4 class="px-2 mb-5 py-2">Basic Information</h4>

          <div class="flex justify-between gap-4">
            <div class="text-cadet w-full lg:w-2/3">
              <div class="info">
                <h5>Agent Name:</h5>
                <h6>{{ agent.name }}</h6>
              </div>

              <div class="info">
                <h5>Company Name:</h5>
                <h6>{{ agent.companyName }}</h6>
              </div>

              <div class="info">
                <h5>Address:</h5>
                <h6>{{ agent.businessAddress }}</h6>
              </div>

              <div class="info">
                <h5>Phone number:</h5>
                <h6>{{ agent.phone }}</h6>
              </div>

              <div class="info">
                <h5>Date Created:</h5>
                <h6>{{ agent.createdAt | moment("DD/MM/YYYY") }}</h6>
              </div>
            </div>

            <div class="w-full lg:w-1/3">
              <div
                class="flex flex-col justify-center items-center h-30 text-darkGreen shadow"
              >
                <p>Last Login</p>
                <p v-if="agent.lastLogin" class="font-bold">
                  {{ agent.lastLogin | moment("Do MMM YYYY ") }}
                </p>
                <p v-else class="font-bold">N/A</p>
              </div>

              <div v-if="agent.lastLogin" class="mt-4">
                <button
                  v-if="agent.isActive"
                  :disabled="processing"
                  class="bg-red-500 py-4 rounded text-white w-full"
                  @click="deactivateAgent('deactivate')"
                >
                  Disable Agent
                </button>

                <button
                  v-else
                  :disabled="processing"
                  class="bg-green-500 py-4 rounded px-3"
                  @click="deactivateAgent('activate')"
                >
                  Enable Admin
                </button>
              </div>

              <div
                v-else
                class="text-black py-4 rounded border border-cadet text-center"
              >
                Not activated yet
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="my-11">
        <div class="table-box">
          <h4 class="px-2 mb-5 py-2">Sub Admin Information</h4>

          <my-table
            :table-data="agent.subAgents"
            :fields="fields"
            :show-page="false"
            :show-search="false"
          >
          </my-table>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  name: "Agent",

  data() {
    let sv = "hidden lg:table-cell";
    let tc = "bg-gray-100 py-3.5 text-cadet font-semibold text-base";
    let dc = "py-2 text-sm text-romanSilver truncate";
    return {
      bus: new Vue(),
      getting: true,
      agent: {
        businessAddress: "FCTA",
        companyName: "UNIQUE MANAI LTD",
        createdAt: "2023-06-01T12:12:50.124Z",
        email: "kushaka85@gmail.com",
        id: "64788b42a2424ac491758bfd",
        isActive: true,
        lastLogin: "2023-06-01T12:54:02.198Z",
        name: "BITRUS AMOS",
        phone: "08032840761",
        profilePhoto: null,
        revenues: [],
        subAgents: [],
        userTypeId: "321ac5435be36a9552c1199a",
      },
      fields: [
        {
          name: "name",
          title: "Agent Name",
          titleClass: `${tc} text-left pl-3`,
          dataClass: `${dc} pl-3`,
        },
        {
          name: "email",
          title: "Email",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv}`,
        },
        {
          name: "phone",
          title: "Phone number",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv}`,
        },
        {
          name: "createdAt",
          title: "Date Created",
          titleClass: `${tc} text-right lg:text-left pr-4 lg:pr-2`,
          dataClass: `${dc} text-right lg:text-left pr-4 lg:pr-2`,
          formatter: (val) => this.moment(val).format("DD-MM-YYYY"),
        },
      ],
    };
  },

  async created() {
    this.agentId = this.$route.params.id;

    await this.getData();
  },

  methods: {
    onAction(action, data, index) {
      console.log("slot action: " + action, data, index);
    },
    onCellClicked(data) {
      this.bus.$emit("cellClicked", data);
    },
    async getData() {
      try {
        this.getting = true;

        const res = await this.$http.get("/auth/agent/" + this.agentId);
        this.getting = false;

        if (!res) {
          this.agent = null;
          return;
        }

        const { data } = res;
        console.log(data);
        this.agent = data.data;
        this.agent.subAgents = this.agent.subAgents.map((d) => {
          delete d.id;
          return d;
        });
      } catch (err) {
        console.log(err);
        this.getting = false;
      }
    },
    async deactivateAgent(action) {
      try {
        this.processing = true;
        let durl = "auth/deactivate-agent/" + this.agentId;

        if (action == "activate") {
          durl = "auth/activate-agent/" + this.agentId;
        }

        const res = await this.$http.post(durl);
        this.processing = false;

        if (!res) {
          return;
        }

        const { data } = res;

        this.$swal({
          icon: "success",
          text: data.message,
        });
      } catch (error) {
        this.processing = false;
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.table-box {
  @apply rounded-md py-4 px-2 w-full mt-5 lg:mt-0;
  border: 0.5px solid #d9d9d9;
  box-shadow: 0px 4px 11px rgba(200, 212, 219, 0.2);
}
.table-box > h4 {
  @apply text-romanSilver font-bold bg-britGray;
}
.table-box .info {
  @apply flex justify-between w-full lg:w-10/12 items-center gap-4 lg:gap-20 px-4 mt-8;
}
.table-box h5 {
  @apply font-semibold w-1/2 lg:w-auto;
}
.table-box h6 {
  @apply w-1/2 lg:w-auto text-right;
}
</style>
