<template>
  <li class="relative">
    <div
      class="px-6 py-3 hover:bg-ansGreen hover:text-white cursor-pointer flex justify-between items-center"
      @click="toggleDropdown(label)"
    >
      <span class="flex items-center gap-3">
        <i :class="`${icon} text-xl`"></i>
        <span>{{ label }}</span>
      </span>
      <svg
        :class="{ 'transform rotate-180': isDropdownOpen(label) }"
        class="w-4 h-4 transition-transform duration-500"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M19 9l-7 7-7-7"
        ></path>
      </svg>
    </div>

    <div v-show="isDropdownOpen(label)" class="ml-3">
      <ul class="ml-3 border-l-2">
        <slot></slot>
      </ul>
    </div>
  </li>
</template>

<script>
export default {
  name: "MenuGroup",

  data() {
    return {
      dropdowns: {},
    };
  },

  props: {
    label: {
      type: String,
      default: null,
      required: true,
    },
    icon: {
      type: String,
      default: null,
      required: true,
    },
  },

  methods: {
    toggleDropdown(dropdown) {
      this.$set(this.dropdowns, dropdown, !this.isDropdownOpen(dropdown));
    },
    isDropdownOpen(dropdown) {
      return this.dropdowns[dropdown] || false;
    },
  },
};
</script>

<style scoped></style>
