<template>
  <div class="font-source">
    <section class="block lg:flex justify-between items-center">
      <h4 class="font-semibold text-ansBlack text-lg lg:text-xl">
        Revenue Heads
      </h4>

      <div
        class="flex justify-between gap-5 mt-5 lg:mt-0 w-full lg:w-auto font-semibold text-sm lg:text-base"
      >
        <button
          @click="showCreateItem = true"
          class="bg-ansGreen text-white px-4 py-2 rounded flex gap-3 items-center w-1/2 lg:w-auto"
        >
          <img src="@/assets/images/icons/plus-white.svg" alt="icons" />
          <span>Create new Head</span>
        </button>
      </div>
    </section>

    <section class="my-11">
      <div class="table-box">
        <h4 class="px-2 mb-5">List of revenue heads</h4>

        <api-table
          apiUrl="/revenue-head/head"
          :fields="fields"
          :show-page="true"
          :show-search="true"
        >
          <template slot="actions" slot-scope="props">
            <div class="flex gap-3">
              <button
                class="px-3 py-1.5 text-white bg-blue-500 rounded"
                @click="addSubItem(props.rowData.id)"
              >
                Add Subhead
              </button>
              <!-- <button
                class="px-3 py-1.5 text-white bg-ansGreen rounded"
                @click="onAction('edit-item', props.rowData, props.rowIndex)"
              >
                Edit
              </button>
              <button
                class="px-3 py-1.5 text-white bg-cadet rounded"
                @click="onAction('delete-item', props.rowData, props.rowIndex)"
              >
                Disable
              </button> -->
            </div>
          </template>
        </api-table>
      </div>
    </section>

    <Modal v-if="showCreateItem" v-model="showCreateItem">
      <CreateRevenueHead @add="onAddItem" />
    </Modal>

    <Modal v-if="showCreateSub" v-model="showCreateSub">
      <CreateSubHead :headId="headId" @add="onAddSubItem" />
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/app/Modal.vue";
import CreateRevenueHead from "@/components/admin/CreateRevenueHead.vue";
import CreateSubHead from "@/components/admin/CreateSubHead.vue";
export default {
  name: "RevenueHeads",

  components: {
    Modal,
    CreateRevenueHead,
    CreateSubHead,
  },

  data() {
    let sv = "hidden lg:table-cell";
    let tc = "bg-gray-100 py-3.5 text-cadet font-semibold text-sm";
    let dc = "py-2 text-xs text-romanSilver";
    return {
      showCreateSub: false,
      showCreateItem: false,
      headId: null,
      fields: [
        {
          name: "id",
          title: "S/N",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "headName",
          title: "Head name",
          titleClass: `${tc} text-left`,
          dataClass: `${dc} uppercase`,
        },
        {
          name: "headCode",
          title: "Head code",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv} uppercase`,
        },
        {
          name: "categoryCode",
          title: "Category",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv}`,
        },
        {
          name: "createdAt",
          title: "Date Created",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv}`,
          formatter: (val) => this.moment(val).format("DD-MM-YYYY"),
        },
        {
          name: "actions",
          title: "Actions",
          titleClass: `${tc} text-right lg:text-left pr-4`,
          dataClass: `${dc} flex justify-end lg:justify-start`,
        },
      ],
    };
  },

  methods: {
    onAction(action, data, index) {
      console.log("slot action: " + action, data, index);
    },
    onAddItem() {
      this.showCreateItem = false;
      this.$events.fire("table-refresh");
    },
    addSubItem(id) {
      this.headId = id;
      this.showCreateSub = true;
    },
    onAddSubItem() {
      this.showCreateSub = false;
    },
  },
};
</script>

<style scoped>
.table-box {
  @apply rounded-md py-4 px-2 w-full mt-5 lg:mt-0;
  border: 0.5px solid #d9d9d9;
  box-shadow: 0px 4px 11px rgba(200, 212, 219, 0.2);
}
.table-box h4 {
  @apply text-romanSilver font-bold;
}
</style>
