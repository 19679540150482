import { render, staticRenderFns } from "./CreateRevenueHead.vue?vue&type=template&id=154e9ac6&scoped=true&"
import script from "./CreateRevenueHead.vue?vue&type=script&lang=js&"
export * from "./CreateRevenueHead.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "154e9ac6",
  null
  
)

export default component.exports