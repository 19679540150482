<template>
  <div class="font-source">
    <h4 class="px-5 py-2.5 bg-ansLemon text-ansGreen text-center">
      Edit an Institution
    </h4>

    <div class="px-4 lg:px-20 mt-2.5">
      <label for="institutionName"> Institution name </label>
      <input
        type="text"
        class="input uppercase"
        id="institutionName"
        placeholder="Enter institution name"
        readonly
        :value="rowData.institutionName"
      />
    </div>

    <div class="px-4 lg:px-20 mt-2.5">
      <label for="institutionCode"> Institution Code </label>
      <input
        type="text"
        class="input"
        id="institutionCode"
        placeholder="Enter institiution code"
        readonly
        :value="rowData.institutionCode"
      />
    </div>

    <div class="px-4 lg:px-20 mt-4">
      <label for="institutionSigneeName">
        Instituition Signatory Name
        <span class="text-red-400">*</span>
      </label>
      <input
        type="text"
        class="input uppercase"
        id="institutionSigneeName"
        placeholder="Enter signatory name"
        v-model="form.institutionSigneeName"
      />
    </div>

    <div class="px-4 lg:px-20 mt-4">
      <label for="institutionSignature">
        Instituition Signature
        <span class="text-red-400">*</span>
      </label>
      <div class="flex gap-2">
        <img
          v-if="rowData.institutionSignature"
          :src="rowData.institutionSignature"
          alt="Institution Signature"
          class="max-h-12"
        />
        <!-- TODO: limit file size -->
        <input
          type="file"
          accept=".png, .jpeg, .jpg"
          class="block w-full rounded border py-2"
          id="institutionSignature"
          @change="setFile"
        />
      </div>
    </div>

    <div class="flex justify-center px-4 lg:px-20 mt-8 pb-3">
      <button
        :disabled="processing || !createReady"
        @click="addData"
        class="bg-ansGreen text-white w-full text-center py-3 rounded font-semibold text-xl flex gap-2 items-center justify-center"
      >
        <span>Update</span>
        <spinner v-if="processing" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditInstitution",

  props: {
    rowData: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      processing: false,
      form: {
        institutionSigneeName: this.rowData.institutionSigneeName,
        institutionSignature: this.rowData.institutionSignature,
      },
    };
  },

  computed: {
    createReady() {
      return (
        Boolean(this.form.institutionSigneeName) &&
        this.form.institutionSignature
      );
    },
  },

  methods: {
    setFile(e) {
      const files = e.target.files;
      if (!files.length) return;

      this.form.institutionSignature = files[0];
    },

    async addData() {
      try {
        if (!this.createReady) {
          this.$swal({
            icon: "error",
            text: "Fill all required fields",
          });
          return;
        }
        this.processing = true;

        const res = await this.$http.postForm(
          `/institutions/signature/${this.rowData._id}`,
          {
            ...this.form,
            institutionSigneeName: this.form.institutionSigneeName
              ? this.form.institutionSigneeName.toUpperCase()
              : this.form.institutionSigneeName,
          }
        );

        this.processing = false;
        if (!res) {
          return;
        }

        const { data } = res;

        this.$swal({
          icon: "success",
          text: data.message,
        });
        // console.log(data.newUser);
        this.$emit("done", data.newUser);
      } catch (error) {
        this.processing = false;
        console.log({ error });
      }
    },
  },
};
</script>

<style scoped></style>
