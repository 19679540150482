<template>
  <div class="font-source">
    <h4 class="px-5 py-2.5 bg-ansLemon text-ansGreen text-center">
      Create an Admin
    </h4>

    <div class="px-4 lg:px-20 mt-2.5">
      <label for="adminName">
        Admin name
        <span class="text-red-400">*</span>
      </label>
      <input
        type="text"
        class="input"
        id="adminName"
        placeholder="Enter admin name"
        v-model="form.name"
      />
    </div>

    <div class="px-4 lg:px-20 mt-2.5">
      <label for="email">
        Admin Email
        <span class="text-red-400">*</span>
      </label>
      <input
        type="email"
        class="input"
        id="email"
        placeholder="Enter admin email"
        v-model="form.email"
      />
    </div>

    <div class="px-4 lg:px-20 mt-4">
      <label for="companyName">
        Company name
        <span class="text-red-400">*</span>
      </label>
      <input
        type="text"
        class="input"
        id="companyName"
        placeholder="Enter your company name"
        v-model="form.companyName"
      />
    </div>

    <div class="px-4 lg:px-20 mt-4">
      <label for="address">
        Address
        <span class="text-red-400">*</span>
      </label>
      <input
        type="text"
        class="input"
        id="address"
        placeholder="Enter your business address"
        v-model="form.businessAddress"
      />
    </div>

    <div class="px-4 lg:px-20 mt-4">
      <label for="phone">
        Phone number
        <span class="text-red-400">*</span>
      </label>
      <input
        type="number"
        class="input"
        id="phone"
        pattern="/\d*$/"
        onKeyPress="if(this.value.length==11) return false;"
        placeholder="Enter admin phone number"
        v-model="form.phone"
      />
    </div>

    <div class="px-4 lg:px-20 mt-2.5">
      <label for="categoryId">
        Admin role
        <span class="text-red-400">*</span>
      </label>
      <v-select
        id="categoryId"
        label="roleName"
        :reduce="(b) => b._id"
        :options="roles"
        v-model="form.role"
        placeholder="-- select role --"
      ></v-select>
    </div>

    <div class="flex justify-center px-4 lg:px-20 mt-8 pb-3">
      <button
        :disabled="processing || !createReady"
        @click="addAdmin"
        class="bg-ansGreen text-white w-full text-center py-4 rounded font-semibold text-xl flex gap-2 items-center justify-center"
      >
        <span>Add Admin</span>
        <spinner v-if="processing" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateAdmin",
  // TODO:  Test admin creation

  data() {
    return {
      processing: false,
      form: {
        name: null,
        companyName: null,
        email: null,
        businessAddress: null,
        phone: null,
        resetUrl: `${window.location.origin}/reset-password`,
        role: null,
      },
      roles: [],
    };
  },

  async created() {
    await this.getRoles();
  },

  computed: {
    createReady() {
      return (
        !!this.form.phone &&
        this.form.email &&
        this.form.name &&
        this.form.companyName &&
        this.form.businessAddress &&
        this.form.role
      );
    },
  },

  methods: {
    async getRoles() {
      try {
        const res = await this.$http.get("/roles?limit=100");

        if (!res) {
          return;
        }

        const { data } = res;
        this.roles = data.data.data;
      } catch (err) {
        console.log(err);
        this.getting = false;
      }
    },

    async addAdmin() {
      try {
        if (!this.createReady) {
          this.$swal({
            icon: "error",
            text: "Fill all required fields",
          });
          return;
        }
        this.processing = true;

        const res = await this.$http.post("/auth/create-admin", this.form);

        this.processing = false;
        if (!res) {
          return;
        }

        const { data } = res;

        this.$swal({
          icon: "success",
          text: data.message,
        });
        this.$emit("add-admin", data.newUser);
      } catch (error) {
        this.processing = false;
        console.log({ error });
      }
    },
  },
};
</script>

<style scoped></style>
