<template>
  <div class="font-source">
    <div v-if="getting" class="flex justify-center items-center h-[50vh]">
      <spinner class="w-20 h-20" color="ansGreen" />
    </div>

    <div
      v-else-if="!getting && !agent"
      class="flex flex-col gap-4 justify-center items-center h-[50vh]"
    >
      <img
        class="mx-auto"
        src="@/assets/images/notfound.svg"
        alt="loading icon"
        width="168"
        height="170"
      />
      <p class="text-center fw-bold">TaxPayer Not Found!</p>
    </div>

    <div v-else>
      <section class="flex gap-4 items-center">
        <button @click="$router.go(-1)" class="p-4">
          <img src="@/assets/images/icons/back-arrow.svg" alt="icons" />
        </button>

        <h4 class="font-semibold text-ansBlack text-lg lg:text-xl">
          {{ agent.companyName }}
        </h4>
      </section>

      <section class="mb-11 mt-4">
        <div class="table-box">
          <h4 class="px-2 mb-5 py-2">Basic Information</h4>

          <div class="flex justify-between gap-4">
            <div class="text-cadet w-full lg:w-2/3">
              <div class="info">
                <h5>Customer Name:</h5>
                <h6 class="uppercase">{{ agent.accountName }}</h6>
              </div>

              <div class="info">
                <h5>Account Type:</h5>
                <h6 class="uppercase text-sm">{{ agent.userType }}</h6>
              </div>

              <div class="info">
                <h5>Address:</h5>
                <h6 class="capitalize">{{ agent.customerAddress }}</h6>
              </div>

              <div class="info">
                <h5>Phone number:</h5>
                <h6>{{ agent.phone }}</h6>
              </div>

              <div class="info">
                <h5>Date Created:</h5>
                <h6>{{ agent.createdAt | moment("DD/MM/YYYY") }}</h6>
              </div>

              <div class="info">
                <h5>Status:</h5>
                <h6>{{ agent.isOnboarded ? "Onboarded" : "Not Onboarded" }}</h6>
              </div>
            </div>

            <div class="w-full lg:w-1/3">
              <div
                class="flex flex-col justify-center items-center h-30 text-darkGreen shadow"
              >
                <p>Last Login</p>
                <p v-if="agent.lastLogin" class="font-bold">
                  {{ agent.lastLogin | moment("Do MMM YYYY ") }}
                </p>
                <p v-else class="font-bold">N/A</p>
              </div>

              <div v-if="agent.lastLogin" class="mt-4">
                <!-- <button
                  v-if="agent.isActive"
                  :disabled="processing"
                  class="bg-red-500 py-4 rounded text-white w-full"
                  @click="deactivateAgent('deactivate')"
                >
                  Disable Agent
                </button>

                <button
                  v-else
                  :disabled="processing"
                  class="bg-green-500 py-4 rounded"
                  @click="deactivateAgent('activate')"
                >
                  Enable Agent
                </button> -->
              </div>

              <div
                v-else
                class="text-black py-4 rounded border border-cadet text-center"
              >
                Not activated yet
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  name: "AdminTaxPayer",

  data() {
    return {
      bus: new Vue(),
      getting: true,
      agent: {
        businessAddress: "FCTA",
        companyName: "UNIQUE MANAI LTD",
        createdAt: "2023-06-01T12:12:50.124Z",
        email: "kushaka85@gmail.com",
        id: "64788b42a2424ac491758bfd",
        isActive: true,
        lastLogin: "2023-06-01T12:54:02.198Z",
        name: "BITRUS AMOS",
        phone: "08032840761",
        profilePhoto: null,
        revenues: [],
        subAgents: [],
        userType: "personal",
      },
    };
  },

  async created() {
    this.agentId = this.$route.params.id;

    await this.getData();
  },

  methods: {
    onAction(action, data, index) {
      console.log("slot action: " + action, data, index);
    },
    onCellClicked(data) {
      this.bus.$emit("cellClicked", data);
    },
    async getData() {
      try {
        this.getting = true;

        const res = await this.$http.get("/taxpayer/" + this.agentId);
        this.getting = false;

        if (!res) {
          this.agent = null;
          return;
        }

        const { data } = res;
        console.log(data);
        this.agent = data.data;
        this.agent.subAgents = this.agent.subAgents.map((d) => {
          delete d.id;
          return d;
        });
      } catch (err) {
        console.log(err);
        this.getting = false;
      }
    },
  },
};
</script>

<style scoped>
.table-box {
  @apply rounded-md py-4 px-2 w-full mt-5 lg:mt-0;
  border: 0.5px solid #d9d9d9;
  box-shadow: 0px 4px 11px rgba(200, 212, 219, 0.2);
}
.table-box > h4 {
  @apply text-romanSilver font-bold bg-britGray;
}
.table-box .info {
  @apply flex justify-between w-full lg:w-10/12 items-center gap-4 lg:gap-20 px-4 mt-8;
}
.table-box h5 {
  @apply font-semibold w-1/2 lg:w-auto;
}
.table-box h6 {
  @apply w-1/2 lg:w-auto text-right;
}
</style>
