<template>
  <div class="font-source">
    <h4 class="px-5 py-2.5 bg-ansLemon text-ansGreen text-center">
      Create New Role
    </h4>

    <div class="px-4 lg:px-20 mt-2.5">
      <label for="roleName">
        Role Name
        <span class="text-red-400">*</span>
      </label>
      <input
        type="text"
        class="input"
        id="roleName"
        placeholder="Enter role name"
        v-model="form.roleName"
      />
    </div>

    <div class="px-4 lg:px-20 mt-4">
      <label class="">Choose Permissions</label>

      <div class="grid grid-cols-3 gap-y-2 mt-2">
        <label
          v-for="(perm, i) in form.permissions"
          :key="i"
          class="flex gap-2 text-sm"
        >
          <input type="checkbox" v-model="perm.isChecked" />
          <span class="uppercase">{{ perm.action }}</span>
        </label>
      </div>
    </div>

    <div class="flex justify-center px-4 lg:px-20 mt-8 pb-3">
      <button
        :disabled="processing || !createReady"
        @click="createData"
        class="bg-ansGreen text-white w-full text-center py-4 rounded font-semibold text-xl flex gap-2 items-center justify-center"
      >
        <span>Add Role</span>
        <spinner v-if="processing" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateRole",

  data() {
    return {
      getting: false,
      processing: false,
      form: {
        roleName: null,
        permissions: [
          {
            _id: "665fb009d8aae4b53979554c",
            action: "get-staff",
            isChecked: false,
            createdAt: "2024-06-05T00:23:37.920Z",
            updatedAt: "2024-06-05T00:23:37.920Z",
            __v: 0,
          },
          {
            _id: "64b5ad92f0ffa57066e2c071",
            action: "get-user",
            isChecked: false,
            description: "Get users data",
            __v: 0,
            createdAt: "2024-03-21T09:24:29.957Z",
            updatedAt: "2024-03-21T09:24:29.957Z",
          },
          {
            _id: "29b5ad92f0ffa57066e2c456",
            action: "create-role",
            isChecked: false,
            description: "Create a new role",
            __v: 0,
            createdAt: "2024-03-21T09:24:29.956Z",
            updatedAt: "2024-03-21T09:24:29.956Z",
          },
          {
            _id: "64c903484b6f1ccca32caeb2",
            action: "update-user",
            isChecked: false,
            description: "SUpdates any user in the system",
            __v: 0,
            createdAt: "2024-03-21T09:24:29.949Z",
            updatedAt: "2024-03-21T09:24:29.949Z",
          },
          {
            _id: "66602e5d3f3ef3c910983295",
            action: "create-user",
            isChecked: false,
            description: "Creates any user in the system",
            __v: 0,
            createdAt: "2024-03-21T09:24:29.949Z",
            updatedAt: "2024-03-21T09:24:29.949Z",
          },
        ],
      },
    };
  },

  computed: {
    createReady() {
      const isAnyChecked = this.form.permissions.some(
        (permission) => permission.isChecked === true
      );

      return Boolean(this.form.roleName) && isAnyChecked;
    },
  },

  async created() {
    // TODO:
    // await this.getData();
  },

  methods: {
    async getData() {
      try {
        this.getting = true;

        const res = await this.$http.get(
          `/permissions?timestamp=${new Date().getTime()}&limit=70`
        );
        this.getting = false;

        if (!res) {
          return;
        }

        const { data } = res;

        this.form.permissions = [...data.data.data].map((perm) => {
          perm.isChecked = false;
          return perm;
        });
      } catch (err) {
        console.log(err);
        this.getting = false;
      }
    },

    async createData() {
      try {
        if (!this.createReady) {
          this.$swal({
            icon: "error",
            text: "Fill all required fields",
          });
          return;
        }
        this.processing = true;

        const res = await this.$http.post("/roles", {
          roleName: this.form.roleName,
          permissions: this.permissions
            .filter((perm) => perm.isChecked)
            .map((perm) => perm._id),
        });

        this.processing = false;
        if (!res) {
          return;
        }

        const { data } = res;

        this.$swal({
          icon: "success",
          text: data.message,
        });

        this.$emit("done", data.data);
      } catch (error) {
        this.processing = false;
        console.log({ error });
      }
    },
  },
};
</script>

<style scoped></style>
