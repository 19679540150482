<template>
  <div class="font-source">
    <div v-if="getting" class="flex justify-center items-center h-[50vh]">
      <spinner class="w-20 h-20" color="ansGreen" />
    </div>

    <div
      v-else-if="!getting && !role"
      class="flex flex-col gap-4 justify-center items-center h-[50vh]"
    >
      <img
        class="mx-auto"
        src="@/assets/images/notfound.svg"
        alt="loading icon"
        width="168"
        height="170"
      />
      <p class="text-center fw-bold">Role Not Found!</p>
    </div>

    <div v-else>
      <button
        @click="$router.go(-1)"
        class="px-4 flex gap-2 items-center border-b border-romanSilver"
      >
        <i class="fas fa-long-arrow-alt-left"></i>
        <span>Back</span>
      </button>

      <section class="mb-5 mt-6">
        <div class="table-box flex gap-3 items-center top">
          <h4 class="">Role Name:</h4>

          <h4 class="font-semibold text-ansBlack uppercase">
            {{ role.roleName }}
          </h4>
        </div>
      </section>

      <section class="">
        <div class="table-box">
          <h4 class="px-2 mb-5 py-2">Role Permissions</h4>

          <my-table
            :table-data="role.permissions"
            :fields="fields"
            :show-page="false"
            :show-search="false"
          >
          </my-table>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "Admin Role",

  data() {
    let tc = "bg-gray-100 py-3.5 text-cadet font-semibold text-base";
    let dc = "py-2 text-sm text-romanSilver truncate";
    return {
      getting: true,
      role: {
        _id: "64b5b50579c725e65673e54b",
        roleName: "superadmin",
        __v: 0,
        createdAt: "2024-04-19T19:54:42.053Z",
        updatedAt: "2024-06-25T12:10:04.121Z",

        permissions: [
          {
            _id: "665fb009d8aae4b53979554c",
            action: "get-staff",
            createdAt: "2024-06-05T00:23:37.920Z",
            updatedAt: "2024-06-05T00:23:37.920Z",
            __v: 0,
          },
          {
            _id: "64b5ad92f0ffa57066e2c071",
            action: "get-user",
            description: "Get users data",
            __v: 0,
            createdAt: "2024-03-21T09:24:29.957Z",
            updatedAt: "2024-03-21T09:24:29.957Z",
          },
        ],
        id: "64b5b50579c725e65673e54b",
      },
      fields: [
        {
          name: "id",
          title: "S/N",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "action",
          title: "Permission",
          titleClass: `${tc} text-left pl-3`,
          dataClass: `${dc} pl-3 uppercase`,
        },
        {
          name: "createdAt",
          title: "Date Created",
          titleClass: `${tc} text-right lg:text-left pr-4 lg:pr-2`,
          dataClass: `${dc} text-right lg:text-left pr-4 lg:pr-2`,
          formatter: (val) => this.moment(val).format("DD-MM-YYYY"),
        },
      ],
    };
  },

  async created() {
    this.roleId = this.$route.params.id;

    await this.getData();
  },

  methods: {
    async getData() {
      try {
        this.getting = true;

        const res = await this.$http.get(
          `/roles/${this.roleId}?timestamp=${new Date().getTime()}`
        );
        this.getting = false;

        if (!res) {
          this.role = null;
          return;
        }

        const { data } = res;

        console.log(data.data);
        this.role = data.data;
      } catch (err) {
        console.log(err);
        this.getting = false;
      }
    },
  },
};
</script>

<style scoped>
.table-box {
  @apply rounded-md py-2 px-2 w-full mt-5 lg:mt-0;
  border: 0.5px solid #d9d9d9;
  box-shadow: 0px 4px 11px rgba(200, 212, 219, 0.2);
}
.table-box.top {
  @apply text-romanSilver font-bold bg-britGray;
}
</style>
