<template>
  <div class="font-source">
    <div v-if="getting" class="flex justify-center items-center h-[50vh]">
      <spinner class="w-20 h-20" color="ansGreen" />
    </div>

    <div
      v-else-if="!getting && !role"
      class="flex flex-col gap-4 justify-center items-center h-[50vh]"
    >
      <img
        class="mx-auto"
        src="@/assets/images/notfound.svg"
        alt="loading icon"
        width="168"
        height="170"
      />
      <p class="text-center fw-bold">Role Not Found!</p>
    </div>

    <div v-else>
      <button
        @click="$router.go(-1)"
        class="px-4 flex gap-2 items-center border-b border-romanSilver"
      >
        <i class="fas fa-long-arrow-alt-left"></i>
        <span>Back</span>
      </button>

      <section class="mb-5 mt-6">
        <div class="table-box flex gap-3 items-center top">
          <h4 class="">Role Name:</h4>

          <h4 class="font-semibold text-ansBlack uppercase">
            {{ role.roleName }}
          </h4>
        </div>
      </section>

      <section class="">
        <form @submit.prevent="updateData" class="table-box">
          <h4 class="px-2 mb-5 py-2">Role Permissions</h4>

          <div class="grid grid-cols-3 gap-y-2">
            <label
              v-for="(perm, i) in permissions"
              :key="i"
              class="flex gap-2 text-sm"
            >
              <input type="checkbox" v-model="perm.isChecked" />
              <span class="uppercase">{{ perm.action }}</span>
            </label>
          </div>

          <div class="mt-4 flex justify-end">
            <button
              :disabled="updating"
              class="flex gap-2 bg-ansGreen justify-center text-white px-4 py-2 rounded w-full lg:w-1/3"
              type="submit"
            >
              <span>Update</span>
              <spinner v-if="updating" />
            </button>
          </div>
        </form>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminEditRole",

  data() {
    return {
      getting: true,
      updating: false,
      permissions: [],
      role: {
        _id: "64b5b50579c725e65673e54b",
        roleName: "superadmin",
        __v: 0,
        createdAt: "2024-04-19T19:54:42.053Z",
        updatedAt: "2024-06-25T12:10:04.121Z",
        permissions: [],
        id: "64b5b50579c725e65673e54b",
      },
    };
  },

  async created() {
    await this.$store.dispatch("general/getPermissions");

    this.roleId = this.$route.params.id;

    await this.getData();
  },

  methods: {
    async getData() {
      try {
        this.getting = true;

        const res = await this.$http.get(
          `/roles/${this.roleId}?timestamp=${new Date().getTime()}`
        );
        this.getting = false;

        if (!res) {
          this.role = null;
          return;
        }

        const { data } = res;

        this.role = data.data;

        // mark the permissions as checked, if checked
        this.permissions = [...this.$store.state.general.permissions].map(
          (perm) => {
            if (
              this.role.permissions.findIndex(
                (rp) => rp.action == perm.action
              ) >= 0
            ) {
              perm.isChecked = true;
            }
            return perm;
          }
        );
      } catch (err) {
        console.log(err);
        this.getting = false;
      }
    },

    async updateData() {
      try {
        const rep = await this.$swal({
          icon: "question",
          text: "Are you sure you want to update this role permissions?",
          showDenyButton: true,
          confirmButtonText: "Yes",
          denyButtonText: "No",
        });

        if (!rep.isConfirmed) {
          return;
        }

        this.updating = true;

        const res = await this.$http.patch(`/roles/${this.roleId}`, {
          roleName: this.role.roleName,
          permissions: this.permissions
            .filter((perm) => perm.isChecked)
            .map((perm) => perm._id),
        });
        this.updating = false;

        if (!res) {
          return;
        }

        const { data } = res;
        this.$swal({
          icon: "success",
          text: data.message,
        });
      } catch (err) {
        console.log(err);
        this.updating = false;
      }
    },
  },
};
</script>

<style scoped>
.table-box {
  @apply rounded-md py-2 px-2 w-full mt-5 lg:mt-0;
  border: 0.5px solid #d9d9d9;
  box-shadow: 0px 4px 11px rgba(200, 212, 219, 0.2);
}
.table-box.top {
  @apply text-romanSilver font-bold bg-britGray;
}
</style>
