<template>
  <div class="font-source">
    <h4 class="px-5 py-2.5 bg-ansLemon text-ansGreen text-center">
      Create a Sub Head
    </h4>

    <div class="px-4 lg:px-20 mt-4">
      <label for="subHeadName"> Sub Head name </label>
      <input
        type="text"
        class="input"
        id="subHeadName"
        v-model="form.subHeadName"
        placeholder="Enter sub item name"
      />
    </div>

    <div class="px-4 lg:px-20 mt-4">
      <label for="subHeadCode"> Sub Head code </label>
      <input
        type="text"
        class="input"
        id="subHeadCode"
        v-model="form.subHeadCode"
        placeholder="Enter sub item code"
      />
    </div>

    <div class="flex justify-center px-4 lg:px-20 mt-8 pb-3">
      <button
        :disabled="processing || !addReady"
        @click="add"
        class="bg-ansGreen text-white w-full text-center py-4 rounded font-semibold text-xl flex gap-2 items-center justify-center"
      >
        <span>Add Sub Head</span>
        <spinner v-if="processing" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateSubItem",

  data() {
    return {
      processing: false,
      form: {
        headId: this.headId,
        subHeadName: null,
        subHeadCode: null,
      },
    };
  },

  props: {
    headId: {
      type: String,
      required: true,
    },
  },

  computed: {
    addReady() {
      return this.form.subHeadCode && this.form.subHeadName && this.form.headId;
    },
  },

  methods: {
    async add() {
      try {
        if (!this.addReady) {
          this.$swal({
            icon: "error",
            text: "Please fill the form",
          });
          return;
        }
        this.processing = true;

        const res = await this.$http.post("/revenue-head/subhead", this.form);

        this.processing = false;
        if (!res) {
          return;
        }

        const { data } = res;

        this.$swal({
          icon: "success",
          text: data.message,
        });
        this.$emit("add", data);
      } catch (error) {
        this.processing = false;
        console.log({ error });
      }
    },
  },
};
</script>

<style scoped></style>
