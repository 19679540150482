<template>
  <div class="font-source">
    <div v-if="getting" class="flex justify-center items-center h-[50vh]">
      <spinner class="w-20 h-20" color="ansGreen" />
    </div>

    <div
      v-else-if="!getting && !admin"
      class="flex flex-col gap-4 justify-center items-center h-[50vh]"
    >
      <img
        class="mx-auto"
        src="@/assets/images/notfound.svg"
        alt="loading icon"
        width="168"
        height="170"
      />
      <p class="text-center fw-bold">Admin Not Found!</p>
    </div>

    <div v-else>
      <section class="flex gap-4 items-center">
        <button @click="$router.go(-1)" class="p-4">
          <img src="@/assets/images/icons/back-arrow.svg" alt="icons" />
        </button>

        <h4 class="font-semibold text-ansBlack text-lg lg:text-xl">
          {{ admin.companyName }}
        </h4>
      </section>

      <section class="mb-11 mt-4">
        <div class="table-box">
          <h4 class="px-2 mb-5 py-2">Basic Information</h4>

          <div class="flex justify-between gap-4">
            <div class="text-cadet w-full lg:w-2/3">
              <div class="info">
                <h5>Admin Name:</h5>
                <h6>{{ admin.name }}</h6>
              </div>

              <div class="info">
                <h5>Company Name:</h5>
                <h6>{{ admin.companyName }}</h6>
              </div>

              <div class="info">
                <h5>Address:</h5>
                <h6>{{ admin.businessAddress }}</h6>
              </div>

              <div class="info">
                <h5>Email Address:</h5>
                <h6>{{ admin.email }}</h6>
              </div>

              <div class="info">
                <h5>Phone number:</h5>
                <h6>{{ admin.phone }}</h6>
              </div>

              <div class="info">
                <h5>Date Created:</h5>
                <h6>{{ admin.createdAt | moment("DD/MM/YYYY") }}</h6>
              </div>
            </div>

            <div class="w-full lg:w-1/3">
              <div>
                <div class="info mb-3">
                  <h5>Admin Role:</h5>
                  <h6 class="capitalize">{{ admin.roleName }}</h6>
                </div>
              </div>

              <div
                class="flex flex-col justify-center items-center h-20 text-darkGreen border"
              >
                <p>Last Login</p>
                <p v-if="admin.lastLogin" class="font-bold">
                  {{ admin.lastLogin | moment("Do MMM YYYY ") }}
                </p>
                <p v-else class="font-bold">N/A</p>
              </div>

              <div v-if="admin.lastLogin" class="mt-4">
                <button
                  v-if="admin.isActive"
                  :disabled="processing"
                  class="bg-red-500 py-4 rounded text-white w-full"
                  @click="deactivateAdmin('deactivate')"
                >
                  Disable Admin
                </button>

                <button
                  v-else
                  :disabled="processing"
                  class="bg-green-500 py-4 rounded px-3"
                  @click="deactivateAdmin('activate')"
                >
                  Enable Admin
                </button>
              </div>

              <div
                v-else
                class="text-black py-4 rounded border border-cadet text-center"
              >
                Not activated yet
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="my-11">
        <div class="table-box">
          <h4 class="px-2 mb-5 py-2">Admin Permissions</h4>

          <form @submit.prevent="updatePermission" class="">
            <div class="grid grid-cols-5 px-2 gap-2">
              <label
                v-for="(perm, i) in permissions"
                :key="i"
                class="text-sm align-middle"
              >
                <input type="checkbox" v-model="perm.isChecked" />
                <span class="uppercase ml-2">{{ perm.action }}</span>
              </label>
            </div>

            <div class="flex justify-end mt-4">
              <button
                type="submit"
                class="py-2 px-10 bg-bgGreen text-white rounded"
                :disabled="processing"
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "Admin",

  data() {
    return {
      getting: true,
      processing: false,
      admin: {
        businessAddress: "FCTA",
        companyName: "UNIQUE MANAI LTD",
        createdAt: "2023-06-01T12:12:50.124Z",
        email: "kushaka85@gmail.com",
        id: "64788b42a2424ac491758bfd",
        isActive: true,
        lastLogin: "2023-06-01T12:54:02.198Z",
        name: "BITRUS AMOS",
        phone: "08032840761",
        profilePhoto: null,
        revenues: [],
        permissions: [],
        userTypeId: "321ac5435be36a9552c1199a",
      },
      permissions: [],
      roleId: null,
    };
  },

  async created() {
    this.adminId = this.$route.params.id;

    this.getting = true;
    await this.getData();
    await this.getRole();
    this.getting = false;
  },

  methods: {
    async getData() {
      try {
        // this.getting = true;

        const res = await this.$http.get("/auth/admins/" + this.adminId);
        // this.getting = false;

        if (!res) {
          this.admin = null;
          return;
        }

        const { data } = res;

        this.admin = data.data;
        this.roleId = this.admin.roleId;
      } catch (err) {
        console.log(err);
        // this.getting = false;
      }
    },

    async getRole() {
      try {
        // this.getting = true;

        const res = await this.$http.get(
          `/roles/${this.roleId}?timestamp=${new Date().getTime()}`
        );
        // this.getting = false;

        if (!res) {
          this.role = null;
          return;
        }

        const { data } = res;

        if (!this.admin.permissions.length) return;

        this.permissions = data.data.permissions;

        console.log({ rolePermi: this.permissions });

        for (const rolePerm of this.permissions) {
          const permIndex = this.admin.permissions.findIndex(
            (perm) => perm.id === rolePerm._id
          );
          if (permIndex > -1) rolePerm.isChecked = true;
        }

        console.log({ adminPerm: this.admin.permissions });
      } catch (err) {
        console.log(err);
        // this.getting = false;
      }
    },

    async deactivateAdmin(action) {
      try {
        this.processing = true;
        let durl = "auth/deactivate-admin/" + this.adminId;

        if (action == "activate") {
          durl = "auth/activate-admin/" + this.adminId;
        }

        const res = await this.$http.post(durl);
        this.processing = false;

        if (!res) {
          return;
        }

        const { data } = res;

        this.$swal({
          icon: "success",
          text: data.message,
        });
      } catch (error) {
        this.processing = false;
        console.log(error);
      }
    },

    async updatePermission() {
      try {
        const rep = await this.$swal({
          icon: "question",
          text: "Are you sure you want to update this admin's permissions?",
          showDenyButton: true,
          confirmButtonText: "Yes",
          denyButtonText: "No",
        });

        if (!rep.isConfirmed) {
          return;
        }

        const permissions = this.permissions
          .filter((permf) => permf.isChecked)
          .map((permm) => permm._id);

        this.processing = true;
        const res = await this.$http.patch(
          `auth/update-permissions/${this.adminId}`,
          {
            permissions,
          }
        );
        this.processing = false;

        if (!res) {
          return;
        }

        const { data } = res;

        this.$swal({
          icon: "success",
          text: data.message,
        });
      } catch (error) {
        this.processing = false;
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.table-box {
  @apply rounded-md py-4 px-2 w-full mt-5 lg:mt-0;
  border: 0.5px solid #d9d9d9;
  box-shadow: 0px 4px 11px rgba(200, 212, 219, 0.2);
}
.info h4,
.table-box > h4 {
  @apply text-romanSilver font-bold bg-britGray;
}
.info {
  @apply flex justify-between w-full lg:w-10/12 items-center gap-4 lg:gap-20 px-4 mt-8;
}
.table-box h5 {
  @apply font-semibold w-1/2 lg:w-auto;
}
.table-box h6 {
  @apply w-1/2 lg:w-auto text-right;
}
</style>
