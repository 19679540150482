<template>
  <div class="font-source">
    <section class="flex justify-between items-center">
      <h4 class="font-semibold text-ansBlack text-lg lg:text-xl">
        Permissions
      </h4>

      <button
        @click="toggleCreate"
        class="bg-ansGreen text-white px-4 py-3 rounded flex gap-3 justify-center items-center font-semibold text-sm lg:text-base"
      >
        <i class="fas fa-plus"></i>
        <span>New Permissions</span>
      </button>
    </section>

    <section class="my-11">
      <div class="table-box">
        <h4 class="px-2 mb-5">List of Permissions</h4>

        <api-table
          apiUrl="/permissions"
          :fields="fields"
          :show-page="true"
          :show-search="true"
          detail-row-component="agent-drop-down"
          :bus="bus"
        >
          <template slot="itemName" slot-scope="props">
            <div @click="onCellClicked(props.rowData)" class="cursor-pointer">
              <span>{{ props.rowData.firstName }} </span>
              <span>{{ props.rowData.lastName }}</span>
            </div>
          </template>

          <template slot="actions" slot-scope="props">
            <div class="flex gap-3">
              <button
                :disabled="props.rowData.updating"
                class="px-2.5 py-1.5 text-white bg-red-500 rounded"
                @click="deleteData(props.rowData)"
              >
                Delete
              </button>
            </div>
          </template>
        </api-table>
      </div>
    </section>

    <Modal v-if="showCreate" v-model="showCreate" size="md">
      <CreatePermission @done="done" />
    </Modal>
  </div>
</template>

<script>
import Vue from "vue";
import Modal from "@/components/app/Modal.vue";
import CreatePermission from "@/components/admin/CreatePermission.vue";

export default {
  name: "AdminPermissions",

  components: {
    Modal,
    CreatePermission,
  },

  data() {
    let sv = "hidden lg:table-cell";
    let tc = "bg-gray-100 py-3.5 text-cadet font-semibold text-sm";
    let dc = "py-2 text-xs text-romanSilver";
    return {
      bus: new Vue(),
      showCreate: false,
      fields: [
        {
          name: "id",
          title: "S/N",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "action",
          title: "Permission Action",
          titleClass: `${tc} text-left`,
          dataClass: `${dc} uppercase`,
        },
        {
          name: "description",
          title: "Description",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv} capitalize`,
        },
        {
          name: "createdAt",
          title: "Date Created",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv}`,
          formatter: (val) => this.moment(val).format("DD-MM-YYYY"),
        },
        {
          name: "actions",
          title: "Actions",
          titleClass: `${tc} text-right lg:text-left pr-4`,
          dataClass: `${dc} flex justify-end lg:justify-start`,
        },
      ],
    };
  },

  methods: {
    done(d) {
      console.log(d);
      this.$events.fire("table-refresh");
      this.toggleCreate();
    },
    toggleCreate() {
      this.showCreate = !this.showCreate;
    },
    async deleteData(d) {
      try {
        const rep = await this.$swal({
          icon: "question",
          text: "Are you sure you want to delete this permission?",
          showDenyButton: true,
          confirmButtonText: "Yes",
          denyButtonText: "No",
        });

        if (!rep.isConfirmed) {
          return;
        }

        d.updating = true;

        const res = await this.$http.delete(`/permissions/${d._id}`);
        d.updating = false;

        if (!res) {
          return;
        }

        const { data } = res;
        this.$events.fire("table-refresh");

        this.$swal({
          icon: "success",
          text: data.message,
        });
      } catch (err) {
        console.log(err);
        d.updating = false;
      }
    },
  },
};
</script>

<style scoped>
.table-box {
  @apply rounded-md py-4 px-2 w-full mt-5 lg:mt-0;
  border: 0.5px solid #d9d9d9;
  box-shadow: 0px 4px 11px rgba(200, 212, 219, 0.2);
}
.table-box h4 {
  @apply text-romanSilver font-bold;
}
</style>
