<template>
  <div class="font-source">
    <section class="block items-center">
      <h4 class="font-semibold text-ansBlack text-lg lg:text-xl">Tax payers</h4>
    </section>

    <section class="my-11">
      <div class="table-box">
        <h4 class="px-2 mb-5">List of taxpayers</h4>

        <api-table
          apiUrl="/taxpayer"
          :fields="fields"
          :show-page="true"
          :show-search="true"
          detail-row-component="agent-drop-down"
          :bus="bus"
        >
          <template slot="accountName" slot-scope="props">
            <div class="cursor-pointer">
              <span v-if="props.rowData.accountName">{{
                props.rowData.accountName
              }}</span>
              <span v-else-if="props.rowData.businessName">{{
                props.rowData.businessName
              }}</span>
              <span v-else
                >{{ props.rowData.firstName }}
                {{ props.rowData.lastName }}</span
              >
            </div>
          </template>

          <template slot="status" slot-scope="props">
            <div class="cursor-pointer">
              <span v-if="props.rowData.isOnboarded">Onboarded</span>
              <span v-else>Not Onboarded</span>
            </div>
          </template>

          <template slot="actions" slot-scope="props">
            <div class="flex gap-3">
              <router-link
                :to="{
                  name: 'Admin TaxPayer',
                  params: {
                    id: props.rowData.userId,
                  },
                }"
                class="px-3 py-1.5 text-white bg-blue-500 rounded"
              >
                View
              </router-link>
            </div>
          </template>
        </api-table>
      </div>
    </section>
  </div>
</template>

<script>
import Vue from "vue";
import AgentDropDown from "@/components/admin/AgentDropDown";
Vue.component("agent-drop-down", AgentDropDown);
export default {
  name: "AdminTaxPayers",

  data() {
    let sv = "hidden lg:table-cell";
    let tc = "bg-gray-100 py-3.5 text-cadet font-semibold text-sm";
    let dc = "py-2 text-xs text-romanSilver";
    return {
      bus: new Vue(),
      showView: false,
      fields: [
        {
          name: "id",
          title: "S/N",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "accountName",
          title: "Customer Name",
          titleClass: `${tc} text-left`,
          dataClass: `${dc} uppercase`,
        },
        {
          name: "userId",
          title: "User ID",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv} `,
        },
        {
          name: "userType",
          title: "User Type",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} uppercase ${sv} `,
        },
        {
          name: "createdAt",
          title: "Date Created",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv}`,
          formatter: (val) => this.moment(val).format("DD-MM-YYYY"),
        },
        {
          name: "status",
          title: "Status",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv}`,
        },
        {
          name: "actions",
          title: "Actions",
          titleClass: `${tc} text-right lg:text-left pr-4`,
          dataClass: `${dc} flex justify-end lg:justify-start`,
        },
      ],
    };
  },

  methods: {
    onAction(action, data, index) {
      console.log("slot action: " + action, data, index);
    },
    onAddItem() {
      this.showCreate = false;
    },
    onAddSubItem() {
      this.showCreateSub = false;
    },
    onCellClicked(data) {
      this.bus.$emit("cellClicked", data);
    },
  },
};
</script>

<style scoped>
.table-box {
  @apply rounded-md py-4 px-2 w-full mt-5 lg:mt-0;
  border: 0.5px solid #d9d9d9;
  box-shadow: 0px 4px 11px rgba(200, 212, 219, 0.2);
}
.table-box h4 {
  @apply text-romanSilver font-bold;
}
</style>
