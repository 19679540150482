<template>
  <div class="font-source">
    <section class="block lg:flex justify-between items-center">
      <h4 class="font-semibold text-ansBlack text-lg lg:text-xl">
        Subscriptions
      </h4>

      <div
        class="flex justify-between gap-5 mt-5 lg:mt-0 w-full lg:w-auto font-semibold text-sm lg:text-base"
      >
        <button
          @click="showCreateItem = true"
          class="bg-ansGreen text-white px-4 py-2 rounded flex gap-3 items-center w-1/2 lg:w-auto"
        >
          <img src="@/assets/images/icons/plus-white.svg" alt="icons" />
          <span>Create subscription</span>
        </button>
      </div>
    </section>

    <section class="my-11">
      <div class="table-box">
        <h4 class="px-2 mb-5">List of subscriptions</h4>

        <api-table
          apiUrl="/subscription"
          :fields="fields"
          :show-page="true"
          :show-search="true"
          detail-row-component="agent-drop-down"
          :bus="bus"
        >
          <template slot="revenueItemName" slot-scope="props">
            <div @click="onCellClicked(props.rowData)" class="cursor-pointer">
              <span>{{ props.rowData.revenueItemName }} </span>
            </div>
          </template>

          <template slot="actions" slot-scope="props">
            <div class="flex gap-3">
              <button
                class="px-3 py-1.5 text-white bg-ansGreen rounded"
                @click="editSub(props.rowData)"
              >
                Edit
              </button>
              <!-- <button
                class="px-3 py-1.5 text-white bg-cadet rounded"
                @click="onAction('delete-item', props.rowData, props.rowIndex)"
              >
                Disable
              </button> -->
            </div>
          </template>
        </api-table>
      </div>
    </section>

    <Modal v-if="showCreateItem" v-model="showCreateItem">
      <CreateSubscription @add="onAddItem" />
    </Modal>
    <Modal v-if="showEidt" v-model="showEidt">
      <EditSubscription
        :revenue="revenue"
        :durations="durations"
        @add="onAddItem"
      />
    </Modal>
  </div>
</template>

<script>
import Vue from "vue";
import Modal from "@/components/app/Modal";
import AgentDropDown from "@/components/admin/AgentDropDown";
import CreateSubscription from "@/components/admin/CreateSubscription";
import EditSubscription from "@/components/admin/EditSubscription";
Vue.component("agent-drop-down", AgentDropDown);
export default {
  name: "Subscriptions",

  components: {
    Modal,
    CreateSubscription,
    EditSubscription,
  },

  data() {
    let sv = "hidden lg:table-cell";
    let tc = "bg-gray-100 py-3.5 text-cadet font-semibold text-sm";
    let dc = "py-2 text-xs text-romanSilver";
    return {
      bus: new Vue(),
      showEidt: false,
      showCreateItem: false,
      revenue: null,
      durations: [],
      fields: [
        {
          name: "id",
          title: "S/N",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "revenueName",
          title: "Revenue name",
          titleClass: `${tc} text-left`,
          dataClass: `${dc} uppercase`,
        },
        {
          name: "revenueCode",
          title: "Revenue code",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv} uppercase`,
        },
        {
          name: "durationName",
          title: "Duration",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv}`,
        },
        {
          name: "amount",
          title: "Amount(₦)",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv} `,
          formatter: (value) => this.moneyFormatter(value),
        },
        {
          name: "createdAt",
          title: "Date Created",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv}`,
          formatter: (val) => this.moment(val).format("DD-MM-YYYY"),
        },
        {
          name: "actions",
          title: "Actions",
          titleClass: `${tc} text-right lg:text-left pr-4`,
          dataClass: `${dc} flex justify-end lg:justify-start`,
        },
      ],
    };
  },

  async created() {
    await this.getDurations();
  },

  methods: {
    onAction(action, data, index) {
      console.log("slot action: " + action, data, index);
    },
    onAddItem() {
      this.$events.fire("table-refresh");
      this.showEidt = false;
      this.showCreateItem = false;
    },
    onCellClicked(data) {
      this.bus.$emit("cellClicked", data);
    },
    editSub(data) {
      this.revenue = data;
      this.showEidt = true;
    },
    async getDurations() {
      try {
        const res = await this.$http.get("/duration?limit=100");

        if (!res) {
          this.durations = [];
          return;
        }

        const { data } = res;
        this.durations = data.data.data;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style scoped>
.table-box {
  @apply rounded-md py-4 px-2 w-full mt-5 lg:mt-0;
  border: 0.5px solid #d9d9d9;
  box-shadow: 0px 4px 11px rgba(200, 212, 219, 0.2);
}
.table-box h4 {
  @apply text-romanSilver font-bold;
}
</style>
