<template>
  <div class="font-source">
    <section>
      <h4 class="px-5 py-2.5 bg-ansLemon text-ansGreen text-center">
        Edit Subscription
      </h4>

      <div class="px-4 lg:px-20 mt-4">
        <h6 class="font-semibold text-sm text-gray-600">
          {{ revenue.revenueName }}
        </h6>
      </div>

      <div class="px-4 lg:px-20 mt-4">
        <label for="duration">Duration</label>
        <v-select
          id="duration"
          label="name"
          :reduce="(b) => b.durCode"
          :options="durations"
          v-model="form.durationCode"
          placeholder="-- select duration --"
        ></v-select>
      </div>

      <div class="px-4 lg:px-20 mt-4">
        <label for="amount">
          Amount
          <span class="text-red-400">*</span>
        </label>
        <money-input
          type="text"
          class="input"
          id="amount"
          v-model="form.amount"
          placeholder="Enter amount"
        />
      </div>
    </section>

    <div class="flex justify-center px-4 lg:px-20 mt-8 pb-3">
      <button
        @click="add"
        :disabled="processing || !createReady"
        class="bg-ansGreen text-white w-full text-center py-4 rounded font-semibold text-xl flex gap-2 items-center justify-center"
      >
        <span>Update Subscription</span>
        <spinner v-if="processing" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditSubscription",

  data() {
    return {
      processing: false,
      hideRev: false,
      form: {
        durationCode: null,
        amount: null,
      },
      revenueItems: [],
    };
  },

  computed: {
    createReady() {
      return !!this.form.durationCode && this.form.amount;
    },
  },

  props: {
    revenue: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    durations: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  async created() {
    this.form.durationCode = this.revenue.durationCode;
    this.form.amount = this.revenue.amount;
  },

  methods: {
    async add() {
      try {
        if (!this.createReady) {
          this.$swal({
            icon: "error",
            text: "Fill all required fields",
          });
          return;
        }

        this.processing = true;

        const res = await this.$http.patch(
          "/subscription/update-subscription/" + this.revenue._id,
          this.form
        );

        this.processing = false;
        if (!res) {
          return;
        }

        const { data } = res;

        this.$swal({
          icon: "success",
          text: data.message,
        });
        this.$emit("add", data);
      } catch (error) {
        this.processing = false;
        console.log({ error });
      }
    },
  },
};
</script>

<style scoped></style>
