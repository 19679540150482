<template>
  <div class="font-source">
    <h4 class="px-5 py-2.5 bg-ansLemon text-ansGreen text-center">
      Create New Permission
    </h4>

    <div class="px-4 lg:px-20 mt-2.5">
      <label for="action">
        Action
        <span class="text-red-400">*</span>
      </label>
      <input
        type="text"
        class="input"
        id="action"
        placeholder="Enter the action"
        v-model="form.action"
      />
    </div>

    <div class="px-4 lg:px-20 mt-2.5">
      <label for="description"> Description </label>
      <input
        type="text"
        class="input"
        id="description"
        placeholder="Enter role name"
        v-model="form.description"
      />
    </div>

    <div class="flex justify-center px-4 lg:px-20 mt-8 pb-3">
      <button
        :disabled="processing || !createReady"
        @click="createData"
        class="bg-ansGreen text-white w-full text-center py-4 rounded font-semibold text-xl flex gap-2 items-center justify-center"
      >
        <span>Add Permission</span>
        <spinner v-if="processing" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateRole",

  data() {
    return {
      getting: false,
      processing: false,
      form: {
        action: null,
        description: null,
      },
    };
  },

  computed: {
    createReady() {
      return Boolean(this.form.action);
    },
  },

  methods: {
    async createData() {
      try {
        if (!this.createReady) {
          this.$swal({
            icon: "error",
            text: "Fill all required fields",
          });
          return;
        }
        this.processing = true;

        const res = await this.$http.post("/permissions", this.form);

        this.processing = false;
        if (!res) {
          return;
        }

        const { data } = res;

        this.$swal({
          icon: "success",
          text: data.message,
        });

        this.$emit("done", data.data);
      } catch (error) {
        this.processing = false;
        console.log({ error });
      }
    },
  },
};
</script>

<style scoped></style>
