<template>
  <div class="font-source">
    <section class="flex justify-between items-center">
      <h4 class="font-semibold text-ansBlack text-lg lg:text-xl">Roles</h4>

      <button
        @click="toggleCreate"
        class="bg-ansGreen text-white px-4 py-3 rounded flex gap-3 justify-center items-center font-semibold text-sm lg:text-base"
      >
        <i class="fas fa-plus"></i>
        <span>New Role</span>
      </button>
    </section>

    <section class="my-11">
      <div class="table-box">
        <h4 class="px-2 mb-5">List of Roles</h4>

        <api-table
          apiUrl="/roles"
          :fields="fields"
          :show-page="true"
          :show-search="true"
          detail-row-component="agent-drop-down"
          :bus="bus"
        >
          <template slot="itemName" slot-scope="props">
            <div @click="onCellClicked(props.rowData)" class="cursor-pointer">
              <span>{{ props.rowData.firstName }} </span>
              <span>{{ props.rowData.lastName }}</span>
            </div>
          </template>

          <template slot="actions" slot-scope="props">
            <div class="flex gap-3">
              <router-link
                class="px-3 py-1.5 text-white bg-ansGreen rounded"
                :to="{ name: 'Admin Role', params: { id: props.rowData._id } }"
              >
                View
              </router-link>
              <router-link
                class="px-3 py-1.5 text-white bg-blue-500 rounded"
                :to="{
                  name: 'Admin Edit Role',
                  params: { id: props.rowData._id },
                }"
              >
                Edit
              </router-link>
            </div>
          </template>
        </api-table>
      </div>
    </section>

    <Modal v-if="showCreate" v-model="showCreate" size="md">
      <CreateRole @done="done" />
    </Modal>
  </div>
</template>

<script>
import Vue from "vue";
import Modal from "@/components/app/Modal.vue";
import CreateRole from "@/components/admin/CreateRole.vue";

export default {
  name: "AdminRoles",

  components: {
    Modal,
    CreateRole,
  },

  data() {
    let sv = "hidden lg:table-cell";
    let tc = "bg-gray-100 py-3.5 text-cadet font-semibold text-sm";
    let dc = "py-2 text-xs text-romanSilver";
    return {
      bus: new Vue(),
      showCreate: false,
      fields: [
        {
          name: "id",
          title: "S/N",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "roleName",
          title: "Role Name",
          titleClass: `${tc} text-left`,
          dataClass: `${dc} uppercase`,
        },
        {
          name: "createdAt",
          title: "Date Created",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv}`,
          formatter: (val) => this.moment(val).format("DD-MM-YYYY"),
        },
        {
          name: "actions",
          title: "Actions",
          titleClass: `${tc} text-right lg:text-left pr-4`,
          dataClass: `${dc} flex justify-end lg:justify-start`,
        },
      ],
    };
  },

  methods: {
    done(d) {
      console.log(d);
      this.$events.fire("table-refresh");
      this.toggleCreate();
    },
    toggleCreate() {
      this.showCreate = !this.showCreate;
    },
  },
};
</script>

<style scoped>
.table-box {
  @apply rounded-md py-4 px-2 w-full mt-5 lg:mt-0;
  border: 0.5px solid #d9d9d9;
  box-shadow: 0px 4px 11px rgba(200, 212, 219, 0.2);
}
.table-box h4 {
  @apply text-romanSilver font-bold;
}
</style>
