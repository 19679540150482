<template>
  <div class="font-source">
    <section>
      <h4 class="px-5 py-2.5 bg-ansLemon text-ansGreen text-center">
        Create a Revenue Item
      </h4>

      <div class="px-4 lg:px-20 mt-2.5">
        <label for="headId">Revenue Head</label>
        <v-select
          id="headId"
          label="headName"
          :reduce="(b) => b._id"
          :options="revenueHeads"
          v-model="form.headId"
          placeholder="-- select revenue head --"
        >
        </v-select>
      </div>

      <div class="px-4 lg:px-20 mt-2.5">
        <label for="institutionId">Institution</label>
        <v-select
          id="institutionId"
          label="institutionName"
          :reduce="(b) => b._id"
          :options="institutions"
          v-model="form.institutionId"
          :filterable="false"
          @search="getInstitutions"
          placeholder="-- select an institution--"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              No results found for <em>{{ search }}</em
              >.
            </template>
            <span v-else clas="text-gray-400 lowercase text-xs">
              Start typing to search for an institution
            </span>
          </template>
        </v-select>
      </div>
    </section>

    <div class="flex justify-center px-4 lg:px-20 mt-8 pb-3">
      <button
        @click="add"
        :disabled="processing || !createReady"
        class="bg-ansGreen text-white w-full text-center py-4 rounded font-semibold text-xl flex gap-2 items-center justify-center"
      >
        <span>Add Item</span>
        <spinner v-if="processing" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateRevenueItem",

  data() {
    return {
      processing: false,
      form: {
        headId: null,
        institutionId: null,
      },
      revenueHeads: [],
      institutions: [],
    };
  },

  computed: {
    createReady() {
      return !!this.form.headId && this.form.institutionId;
    },
  },

  async created() {
    await this.getRevenues();
  },

  methods: {
    async getRevenues() {
      try {
        const res = await this.$http.get("/revenue-head/head?limit=100");

        if (!res) {
          this.revenueHeads = [];
          return;
        }

        const { data } = res;
        this.revenueHeads = data.data.data;
      } catch (err) {
        console.log(err);
      }
    },

    async getInstitutions(search, loading) {
      if (search.length < 2) {
        return;
      }

      try {
        loading(true);
        const res = await this.$http.get("/institutions?search=" + search);

        if (!res) {
          this.institutions = [];
          return;
        }

        const { data } = res;
        this.institutions = data.data.data;
        loading(false);
      } catch (err) {
        loading(false);
        console.log(err);
      }
    },

    async add() {
      try {
        if (!this.createReady) {
          this.$swal({
            icon: "error",
            text: "Fill all required fields",
          });
          return;
        }

        this.processing = true;

        const res = await this.$http.post("/revenue/items", this.form);

        this.processing = false;
        if (!res) {
          return;
        }

        const { data } = res;

        this.$swal({
          icon: "success",
          text: data.message,
        });
        this.$emit("add", data);
      } catch (error) {
        this.processing = false;
        console.log({ error });
      }
    },
  },
};
</script>

<style scoped></style>
