<template>
  <div class="font-source">
    <section class="flex justify-between items-center">
      <h4 class="font-semibold text-ansBlack text-lg lg:text-xl">Receipts</h4>
    </section>

    <section class="my-11">
      <div class="table-box">
        <h4 class="px-2 mb-5">List of generated receipts</h4>
        <api-table
          apiUrl="/receipt"
          :fields="fields"
          :show-page="true"
          :show-search="true"
          :bus="bus"
        >
          <template slot="actions" slot-scope="props">
            <div class="flex gap-3">
              <button
                class="px-2.5 py-1.5 text-white bg-blue-500 rounded"
                @click="viewInvoice(props.rowData, props.rowIndex)"
              >
                View
              </button>
            </div>
          </template>
        </api-table>
      </div>
    </section>

    <Modal v-if="showView" v-model="showView" size="lg">
      <Receipt :receiptNumber="receiptNumber" />
    </Modal>
  </div>
</template>

<script>
import Vue from "vue";
import Modal from "@/components/app/Modal.vue";
import Receipt from "@/components/home/Receipt.vue";
export default {
  name: "AdminReceipts",

  components: {
    Modal,
    Receipt,
  },

  data() {
    let sv = "hidden lg:table-cell";
    let tc = "bg-gray-100 py-3.5 text-cadet font-semibold text-sm";
    let dc = "py-2 text-xs text-romanSilver";
    return {
      bus: new Vue(),
      showView: false,
      receiptNumber: null,
      fields: [
        {
          name: "id",
          title: "S/N",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "receiptNumber",
          title: "Invoice Number",
          titleClass: `${tc} text-left`,
          dataClass: `${dc} uppercase`,
        },
        {
          name: "taxPayerName",
          title: "Customer",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} uppercase ${sv}`,
          formatter: (val) => {
            return val ? val : "<i class='text-red-300'>No Customer<i/>";
          },
        },
        {
          name: "receiptNumber",
          title: "Receipt Number",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv}`,
        },
        {
          name: "amount",
          title: "Amount(₦)",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv} `,
          formatter: (value) => this.moneyFormatter(value),
        },
        {
          name: "period",
          title: "Year",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} uppercase text-xs ${sv} `,
        },
        {
          name: "createdAt",
          title: "Date Created",
          titleClass: `${tc} text-left ${sv}`,
          dataClass: `${dc} ${sv}`,
          formatter: (val) => this.moment(val).format("DD-MM-YYYY"),
        },
        {
          name: "actions",
          title: "Actions",
          titleClass: `${tc} text-right lg:text-left pr-4`,
          dataClass: `${dc} flex justify-end lg:justify-start`,
        },
      ],
    };
  },

  methods: {
    onAddItem() {
      this.$events.fire("table-refresh");
      this.showCreate = false;
    },
    onCellClicked(data) {
      this.bus.$emit("cellClicked", data);
    },
    viewInvoice(data, i) {
      console.log(i, data);
      this.receiptNumber = data.receiptNumber;
      this.showView = true;
    },
  },
};
</script>

<style scoped>
.table-box {
  @apply rounded-md py-4 px-2 w-full mt-5 lg:mt-0;
  border: 0.5px solid #d9d9d9;
  box-shadow: 0px 4px 11px rgba(200, 212, 219, 0.2);
}
.table-box h4 {
  @apply text-romanSilver font-bold;
}
</style>
